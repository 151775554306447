import React, { useState, useEffect }  from 'react'
import { Picture } from 'react-responsive-picture'
import axios from 'axios'
import PostRegister from './post-register'
import { Link } from "gatsby"
import useForm from "../../hooks/useForm"
import maintenanceStyles from "../../styles/pages/maintenance/_maintenance.module.scss"
import validate from "../../components/_maintenance/maintenanceFormValidationRules"
import QuestionInput from '../../components/_newsletter/question_input'
import Checkbox from '../../components/_newsletter/checkbox/checkbox'

import background320 from '../../../static/assets/images/newsletter/background_320.png'
import background768 from '../../../static/assets/images/newsletter/background_768.png'
import background1024 from '../../../static/assets/images/newsletter/background_1024.png'
import background1920 from '../../../static/assets/images/newsletter/background1920.png'

const Register = ({_title}) =>{

    const [register, setRegister] = useState(true)
    const [terms, setTerms] = useState(false)
    const [termsError, setTermsError] = useState(false)
    const [sendDisabled, setSendDisabled] = useState(false)

    const API_URL = process.env.GATSBY_CALOREX_NEWSLETTER_ENDPOINT
  //  const listId = 'bd2d50409ccce87b2bed41585f1f4237'

    const myInputs ={
        nombre: "",
        apellidos: "",
        phone:"",
        email: "",
        marca: "Calorex",
       
    }
   
    const onStep = true
    const { inputs, handleInputChange, handleSubmit, errors } = useForm(formValidCallback, myInputs, validate, onStep, terms, setTermsError)

    const handleCheckBox = (item, type = null) => {
        if (item.checked === undefined) return
    
        if (type === "terms" && item.checked) {
          setTerms(item.checked)
          setTermsError(false)
          return
        } else if(type === "terms" && !item.checked) {
          setTerms(false)
          setTermsError(true)
          return
        }
      }

    function formValidCallback() {
        if(termsError || sendDisabled ) return

        // console.log('es valido el formulario')
        //  console.log('data', inputs)
        setSendDisabled(true)

        axios.post(API_URL, inputs).then(function (response) {
          setRegister(false)  
         // console.log('response -->', response)
        })
         .catch(function (error) {
           console.log(error);
         })
     }

     useEffect(()=>{
        document.querySelector("footer").style.display = "none"
     },[])

    return(
      register ?
      <>
         <div className="container_register">
          <div className="container_register_background-image">
              <Picture
                    sources={[
                      {
                        srcSet: background320,
                        media: "(max-width: 767px)",
                      },
                      {
                        srcSet: background768,
                        media: "(max-width: 1023px)",
                      },
                      {
                        srcSet: background1024,
                        media: "(max-width: 1359px)",
                      },
                      {
                        srcSet: background1920,
                        media: "(min-width: 1360px)",
                      },
                    ]}
                  />
          </div>
          <div className="container_register-info">
            <div className="container_register--title">
                  <p>{_title}</p>
                  <p className="register__description">Recibe nuestras noticias en tu correo.</p>
            </div>
            <div className="container_register--form">
                  <QuestionInput _className="register_input--form" questionName="NOMBRE" onChange={handleInputChange} inputs={inputs.nombre} placeholder="EJ. JUAN MANUEL" name="nombre" required={true} error={errors.nombre} />
                  <QuestionInput _className="register_input--form" questionName="APELLIDOS" onChange={handleInputChange} inputs={inputs.apellidos} placeholder="EJ. LOPEZ" name="apellidos" required={true} error={errors.apellidos} />
                  <QuestionInput _className="register_input--form" questionName="NÚMERO TELEFÓNICO" onChange={handleInputChange} inputs={inputs.phone} placeholder="EJ. 8441234567" name="phone" required={true} error={errors.phone} maxlength="10" />
                  <QuestionInput _className="register_input--form" onChange={handleInputChange} input={inputs.email} questionName="CORREO ELECTRÓNICO" placeholder="EJ. juan@correo.com" name="email" type="email" required={true} error={errors.email} />
                  <div className="container_terminos--button">
                      <div className="container_terminos">
                        <Checkbox label="" className="q-terms" value="terms" onClick={item => handleCheckBox(item, "terms")} />
                        <div className="text-terminos">
                          <p className="label">
                            Acepto los <Link to="/aviso-de-privacidad">términos y condiciones</Link> de la política de protección de datos.
                          </p>
                          { termsError && <p className="terms_errors">Para continuar por favor acepta nuestros términos y condiciones.</p> }
                        </div>
                      </div>
                      <form noValidate id="maintenance__form" className={maintenanceStyles.maintenance__form} method="post" action="/enviado" onSubmit={handleSubmit}>
                          <button id="submit__button" type="submit" className={sendDisabled ? "button_subscribe--disabled button_subscribe" : "button_subscribe"}>{sendDisabled ? "Enviando..." : "Suscribirse"} </button>
                      </form>
                  </div>
              </div>
          </div>
        </div>
      </> :
      <PostRegister />
    )
}

export default Register