import React from 'react'
import { Picture } from "react-responsive-picture"
import { Link } from "gatsby"

import { ButtonSemicircle } from "../../components/buttons/_button_semicircle"
import CalorexLogo from "../../components/svg/commons/calorex_logo"

import familiaCalorex320 from "../../../static/assets/images/about_us/calorex_home_slide_familia_calorex_mb.png"
import familiaCalorex768 from "../../../static/assets/images/about_us/calorex_home_slide_familia_calorex_tb.png"
import familiaCalorexDesktop from "../../../static/assets/images/about_us/calorex_home_slide_familia_calorex_lp.png"

const PostRegister = () =>{
    return (
        <div className="post_register_container">
            <div className="container_logo">
                <CalorexLogo />
            </div>
            <div className="container_title">
                <p> Ahora formas parte del club del confort. </p>
            </div>
            <Picture 
                 className="imagen_family_calorex"
                 sources={[
                  {
                    srcSet: familiaCalorex320,
                    media: "(max-width: 767px)",
                  },
                  {
                    srcSet: familiaCalorex768,
                    media: "(max-width: 1359px)",
                  },
                  {
                    srcSet: familiaCalorexDesktop,
                    media: "(min-width: 1360px)",
                  }
                ]}
            />
            <div className="container_description">
                <p>  ¿Ya sabes cuál es tu Calorex ideal? </p>
            </div>
            <Link to="/tu-calentador-ideal">
              <ButtonSemicircle _onClick="" _text="¡Descubrelo Aquí!" _className="button_exit" />
            </Link>
            <div className="register_listo_text">¡Listo!</div>
            
        </div>
    )
}

export default PostRegister