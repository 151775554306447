import React from 'react'
import Layout from "../components/layout"
import Register from '../content/newsletter/register'
import "../styles/pages/newsletter/newsletter.scss"

const Newsletter = () =>{
    const title= "¡No te pierdas ninguna novedad!"
    return (
        <Layout chat={false}>
            <Register _title={title}/> 
        </Layout>
    ) 
}

export default Newsletter