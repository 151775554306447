import styled from "styled-components"

const StyledCheckbox = styled.label`
    display: flex;
    margin-top: 0px;
    .a-checkbox__input{
        opacity: 0;
        display: none
    }
    &.a-checkbox--reversed{
        .a-checkbox__check{
            position: absolute;
            right: 12px;
            left: auto;           
        }
        
    }
    a-checkbox__p{
        padding-right: 20px;
    }
    .a-checkbox__check{
        width: 20px;
        height: 20px;        
        margin-right: 10px;
        background-color: none;
        box-sizing: border-box;
        border: 2px solid #a3a8ad;        
        position: relative;
        left: 0;
        margin-left: 10px;
        min-width: 20px;
        margin-top: 0px;
      
        /* @media (min-width:1360px){
            margin-left: 0px;
            width: 15px;
            height: 15px;
            min-width: 15px;   
        } */
        svg{
            color: #1F1F27;
            width: 15px;
            height: 15px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate( -50%, -50%); 
            /* @media (min-width:1360px){
                width: 10px;
                height: 10px;
            } */
        }
        &.a-checkbox--checked{
            border: 2px solid #d90b2d;
            background-color: transparent;
        }
        
    }    
    
`

export default StyledCheckbox